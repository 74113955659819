<template>
  <v-container class="tw-pt-0 tw-mt-0">

    <div class="auth-container tw-mt-10">
      <StepsComponent :step="3" />
      <div class="tw-relative tw-min-h-screen">
        <TitleComponent class="tw-mt-8" text="Félicitations, votre compte est créé." />
        <SignupSecondaryTextComponent class="public-text-register tw-mt-4">
          Nous venons de vous envoyer un email récapitulatif que nous vous invitons à conserver soigneusement.
          Important, regardez dans vos
          “courriels indésirables” , on ne sait jamais.<br />
          En cas de perte de votre mot de passe, pas d’inquiétude, vous pourrez le recréer à tout moment.<br /><br />

          Vous pouvez maintenant vous connecter à votre compte. La double authentification sera nécessaire pour
          sécuriser votre connexion.<br />
        </SignupSecondaryTextComponent>
        <v-form ref="form" @submit="onSubmit" v-model="formValid" class="tw-mt-8" autocomplete="off">
          <br />
          <br />
          <br />
          <div class="tw-flex tw-justify-center">
            <PrimaryButtonComponent :style="{
            backgroundColor: `${referer.theme.ctaBgColor} !important`,
            color: `${referer.theme.ctaTextColor} !important` }" type="submit">Connexion</PrimaryButtonComponent>
          </div>
        </v-form>
      </div>
    </div>
  </v-container>
</template>

<script>
import TitleComponent from "@/components/common/TitleComponent";
import SignupSecondaryTextComponent from "@/features/auth/components/SignupSecondaryTextComponent";
import PrimaryButtonComponent from "@/components/common/PrimaryButtonComponent";
import StepsComponent from "@/features/auth/components/StepsComponent";
export default {
  computed: {
    referer() {
      return this.$store.state.referrer;
    },
  },
  components: {
    StepsComponent,
    PrimaryButtonComponent,
    SignupSecondaryTextComponent,
    TitleComponent,
  },
  data() {
    return {
    };
  },
  methods: {
    onSubmit($event) {
      $event.preventDefault();
      this.$router.replace({
        name: "landing-public",
      });
    },
  },
};
</script>

